export default function DynamicNav({ links }) {
  const handleClick = (e, id) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      {links.map((link) => (
        <a
          key={link.url}
          href={link.url}
          onClick={(e) => handleClick(e, link.url.slice(1))}
          className="text-dark5 xl:text-menuMSB xxl:text-bodyLR pt-3"
        >
          {link.text}
        </a>
      ))}
    </>
  );
}

import ButtonSecondary from "../../../buttons/ButtonSecondary";
import Cards from "../elements/Cards";

export default function Desktop() {
  return (
    <div className="xl:container xl:px-16 xxl:px-0 xxl:mx-auto py-24 mobile:hidden">
      <div className="grid grid-cols-2 gap-10">
        <div className="col-1">
          <h3 className="text-h3 text-white">Pricing</h3>
          <p className="text-white text-bodyLR pt-8 pb-20">
            Craft your digital legacy effortlessly with Testament.digital. Enjoy
            full control, unlimited rules, secure sharing—all for just{" "}
            <span className="text-secondary">$12 per year</span>. Your legacy,
            your way, at an affordable price.
          </p>
        </div>

        <div className="flex flex-col justify-self-end">
          <p>
            <span className="text-secondary text-h6">ONLY</span>
            <span className="text-white text-h2">12$ </span>
            <span className="text-white">/Year</span>
          </p>
          <div className="flex justify-end pt-8">
            <ButtonSecondary
              text="Get Started Now"
              padding="px-[38px] py-[14px]"
              fontsize="text-bodyLR"
            />
          </div>
        </div>
      </div>

      <h5 className="text-h5 text-white pb-5">What’s included:</h5>
      <div className="grid grid-cols-3 gap-4">
        <Cards />
      </div>
    </div>
  );
}

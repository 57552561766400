export default function ButtonSecondary({
  text,
  onClick,
  width,
  padding,
  fontsize,
}) {
  return (
    <button
      className={`border  border-secondary ${width} rounded-md bg-secondary color-white ${padding}`}
      onClick={onClick}
    >
      <p className={`text-white ${fontsize}`}>{text}</p>
    </button>
  );
}

import FooterElements from "../elements/FooterElements";

export default function Desktop() {
  const sections = [
    {
      title: "Contact",
      elements: [
        { text: "+ 123-456-7890" },
        { text: "contact@testament.digital" },
        { text: "Mon – Sun: 10am – 10pm" },
      ],
    },
    {
      title: "Useful links",
      elements: [
        { text: "Privacy Policy", url: "/#" },
        { text: "Terms and Conditions", url: "/#" },
        { text: "Cookies", url: "/#" },
      ],
    },
    {
      title: "Follow us",
      elements: [
        { text: "Facebook", url: "/#" },
        { text: "Linkedin", url: "/#" },
        { text: "Twitter", url: "/#" },
      ],
    },
  ];
  return (
    <div className="xl:container xl:px-16 desktop:py-20 xxl:px-0 xxl:mx-auto">
      <div className="grid grid-cols-3 mobile:hidden">
        {sections.map((section, index) => (
          <div key={index} className="flex-row flex mt-5">
            <FooterElements title={section.title} elements={section.elements} />
          </div>
        ))}
      </div>
    </div>
  );
}

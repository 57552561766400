export default function ButtonPrimary({
  text,
  onClick,
  width,
  padding,
  fontsize,
}) {
  return (
    <button
      className={`border ${width} rounded-md bg-primary color-white ${padding}`}
      onClick={onClick}
    >
      <p className={`text-white ${fontsize}`}>{text}</p>
    </button>
  );
}

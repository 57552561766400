import Mobile from "./screen-view/Mobile";
import Desktop from "./screen-view/Desktop";

export default function Header() {
  return (
    <header className="header">
      <Mobile />
      <Desktop />
    </header>
  );
}

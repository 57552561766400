import "./App.scss";
import Header from "./components/header/Header";
import BodyPage from "./components/body/BodyPage";
import Footer from "./components/footer/Footer";

export default function App() {
  return (
    <div className="page">
      <Header />
      <BodyPage />
      <Footer />
    </div>
  );
}

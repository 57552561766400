// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-slider .slick-dots li button:before {
  color: #d1d5db;
  font-size: 19px;
}
.slick-slider .slick-dots li.slick-active button:before {
  color: #314cb6;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAMQ;EACE,cAAA;EACA,eAAA;AALV;AAWQ;EACE,cAAA;AATV","sourcesContent":["//variables color\n\n.slick-slider {\n  .slick-dots {\n    li {\n      button {\n        &:before {\n          color: #d1d5db;\n          font-size: 19px;\n        }\n      }\n    }\n    li.slick-active {\n      button {\n        &:before {\n          color: #314cb6;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import LogoHeader from "../elements/LogoHeader";
import DynamicNav from "../../nav/DynamicNav";
import ButtonPrimary from "../../buttons/ButtonPrimary";
import { UserCircleIcon } from "@heroicons/react/24/solid";

export default function Desktop() {
  const navLinks = [
    { url: "#section-2", text: "How it works" },
    { url: "#section-3", text: "Why choose us" },
    { url: "#section-5", text: "Pricing" },
    { url: "#section-6", text: "Security" },
  ];

  return (
    <div className="xl:container xl:px-16 xxl:px-0 xxl:mx-auto mobile:hidden py-5">
      <div className="grid grid-cols-3">
        <div className="">
          <LogoHeader />
        </div>
        <div className="flex xl:gap-x-4 xxl:gap-x-12">
          <DynamicNav links={navLinks} />
        </div>
        <div className="flex justify-end">
          <div className="flex p-myCustomP1">
            <UserCircleIcon className="h-6 w-6 text-blue-500" />
            <a href="/#" target="_blank" className="text-primary">
              Login
            </a>
          </div>
          <ButtonPrimary
            text="Create account"
            onClick="#"
            padding="desktop:px-[24px] desktop:py-[6px]"
            fontsize="text-bodyMR"
          />
        </div>
      </div>
    </div>
  );
}

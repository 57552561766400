import CloseIcon from "../../../icons/CloseIcon";
import OpenIcon from "../../../icons/OpenIcon";

export default function ButtonMenu({ toggleMenu, isOpen }) {
  return (
    <button
      id="closeButton"
      className="focus:outline-none"
      onClick={toggleMenu}
    >
      {isOpen ? <CloseIcon /> : <OpenIcon />}
    </button>
  );
}
